import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import Hero from "../components/Hero"
import axios from "axios"
import Alert from "../elements/Alert"
import { formatNumber } from "../helper"
import KBATable from "../components/KBATable"
import GaugeChart from "react-gauge-chart"
import StaticRangeSlider from "../components/StaticRangeSlider"
import Spinner from "../elements/Spinner"
import { Helmet } from "react-helmet"
import { convertToAntiSlug } from "../env"

function Details() {
	const { vehiclemeta, id } = useParams<any>()

	const [verhicleDetailsData, setverhicleDetailsData] = useState<any>(null)
	const [isLoadingHTSN, setIsLoadingHTSN] = useState<boolean>(false)
	const [showCopiedMsg, setShowCopiedMsg] = useState<boolean>(false)

	useEffect(() => {
		loadHsnTsnData()
		// eslint-disable-next-line
	}, [])

	function loadHsnTsnData() {
		setIsLoadingHTSN(true)

		const data = {
			method: "vehicleDetails",
			id: id,
		}

		axios
			.post("https://webstanding.de/apps/hsntsnnextjs/data.php?cv=" + Date.now(), data)
			.then(res => {
				if (res.status === 200) {
					setverhicleDetailsData(res.data)
				}
				setIsLoadingHTSN(false)
			})
			.catch(e => {
				setIsLoadingHTSN(false)
			})
	}

	function berechneKFZSteuer(hubraum, kraftstoff) {
		// Kraftstofftypen und ihre Steuersätze in Cent pro cm³ Hubraum
		const steuersaetze = {
			Benzin: 2.0,
			"Benzin/Alkohol": 2.0,
			"Benzin/Autogas": 2.0,
			"Benzin/Elektro": 2.0,
			"Benzin/Erdgas": 1.3,
			Diesel: 9.5,
			"Diesel/Elektro": 9.5,
			Elektro: 0.0,
			Wankel: 2.0,
			"Wasserstoff/Elektro": 0.0,
			Zweitakt: 2.0,
		}

		// Bestimme den Steuersatz für den angegebenen Kraftstofftyp
		const steuersatz = steuersaetze[kraftstoff]

		// Berechne die Steuer basierend auf dem Hubraum und dem Steuersatz
		const steuer = hubraum * steuersatz

		// Runde die Steuer auf zwei Nachkommastellen und gib sie zurück
		return Math.round(steuer * 100) / 100
	}

	return (
		<>
			<Helmet>
				<title>{convertToAntiSlug(vehiclemeta)} - HSN TSN Suche </title>
				<meta name="" content={"Hier findest du die  HSN und TSN für " + convertToAntiSlug(vehiclemeta) + "."} />
				<link rel="canonical" href={"https://hsntsnsuchmaschine.de/" + vehiclemeta + "/" + id} />
			</Helmet>

			<Hero imgAlt={"HSN TSN"} imgPath={"/images/car.png"}>
				<h1 className="h1 pb-0">
					<Link to="/">HSN TSN</Link>{" "}
					{verhicleDetailsData && verhicleDetailsData.length > 0 && "- " + verhicleDetailsData[0].vehicletype}
				</h1>
			</Hero>

			<div className="bg-green u-seperator z-20 relative"></div>
			{verhicleDetailsData && verhicleDetailsData.length > 0 && (
				<>
					<div className="relative z-10 bg-black ">
						<section className="max-w-4xl mx-auto">
							<h2 className="h2">Fahrzeugdaten</h2>
							<div className="grid grid-cols-1  sm:grid-cols-2  gap-2  pb-6 text-left">
								<div className="card bg-green/20">
									<div>HSN</div> {verhicleDetailsData[0].hsn || "?"}
									<span
										title="copy"
										onClick={() => {
											navigator.clipboard.writeText(verhicleDetailsData[0].hsn)
											setShowCopiedMsg(true)
											setTimeout(() => setShowCopiedMsg(false), 3000)
										}}
										className="cursor-pointer float-right"
									>
										<svg
											className="inline-block w-[30px] transition-all ease-in h-auto mx-1 sm:mx-3 fill-white stroke-blackLight"
											focusable="false"
											aria-hidden="true"
											viewBox="0 0 24 24"
											data-testid="ContentCopyOutlinedIcon"
										>
											<path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
										</svg>
									</span>
								</div>
								<div className="card bg-green/20">
									<div>TSN</div> {verhicleDetailsData[0].tsn || "?"}
									<span
										title="copy"
										onClick={() => {
											navigator.clipboard.writeText(verhicleDetailsData[0].tsn || "?")
											setShowCopiedMsg(true)
											setTimeout(() => setShowCopiedMsg(false), 3000)
										}}
										className="cursor-pointer float-right"
									>
										<svg
											className="inline-block w-[30px] transition-all ease-in h-auto mx-1 sm:mx-3 fill-white stroke-blackLight"
											focusable="false"
											aria-hidden="true"
											viewBox="0 0 24 24"
											data-testid="ContentCopyOutlinedIcon"
										>
											<path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
										</svg>
									</span>
								</div>
								<div className="whitespace-nowrap card">
									<div>Baujahr</div> {verhicleDetailsData[0].yearFrom || "?"} -{" "}
									{verhicleDetailsData[0].yearTo || "heute"}
								</div>
								<div className="card">
									<div>Leistung</div>
									{verhicleDetailsData[0].power || "?"} PS
								</div>
								<div className="card">
									<div>Hubraum</div>
									{formatNumber(verhicleDetailsData[0].ccm)} ccm
								</div>
								<div className="card">
									<div>Kraftstoff</div>
									{verhicleDetailsData[0].fuel || "?"}
								</div>
								<div className="card">
									<div>KFZ Steuer</div>ca.{" "}
									{(berechneKFZSteuer(verhicleDetailsData[0].ccm, verhicleDetailsData[0].fuel) / 100)
										.toFixed(2)
										.replace(".", ",")}{" "}
									EUR
								</div>

								{verhicleDetailsData[0].anzahl2024 && (
									<div className="card">
										<div>Angemeldete Fahrzeuge</div>
										{formatNumber(verhicleDetailsData[0].anzahl2024)}
									</div>
								)}
							</div>
							<p className="mb-6">
								Der {verhicleDetailsData[0].vehicletype} ist ein beeindruckendes Auto, der{" "}
								{verhicleDetailsData[0].yearFrom} - {verhicleDetailsData[0].yearTo} hergestellt wurde. Mit{" "}
								{verhicleDetailsData[0].power} PS unter der Haube bietet es eine beeindruckende Leistung und eine
								dynamische Fahrdynamik. Der {formatNumber(verhicleDetailsData[0].ccm)}
								-ccm-Motor sorgt für eine kraftvolle Beschleunigung und eine angenehme Fahrerfahrung.
							</p>
							<p className="mb-6">
								Dieses Fahrzeug läuft mit {verhicleDetailsData[0].fuel}, was sowohl eine gute Kraftstoffeffizienz als
								auch eine reibungslose Leistung ermöglicht. Es ist ein vielseitiges Auto, das sowohl im Stadtverkehr als
								auch auf Autobahnen eine großartige Leistung bietet.
							</p>
							<p className="mb-6">
								Eine weitere wichtige Information ist die KFZ Steuer, die für dieses Fahrzeug anfällt. Die geschätzte
								Höhe der KFZ Steuer beträgt{" "}
								{(berechneKFZSteuer(verhicleDetailsData[0].ccm, verhicleDetailsData[0].fuel) / 100)
									.toFixed(2)
									.replace(".", ",")}{" "}
								EUR. Diese Steuer wird jährlich fällig und hängt von verschiedenen Faktoren ab, wie zum Beispiel dem
								Hubraum des Fahrzeugs und dem Ausstoß von Schadstoffen.
							</p>
							{(verhicleDetailsData[0].anzahl2023 ||
								verhicleDetailsData[0].anzahl2022 ||
								verhicleDetailsData[0].anzahl2021 ||
								verhicleDetailsData[0].anzahl2020 ||
								verhicleDetailsData[0].anzahl2019) && <KBATable data={verhicleDetailsData[0]} />}

							{verhicleDetailsData[0].haftpficht &&
								verhicleDetailsData[0].teilkasko &&
								verhicleDetailsData[0].vollkasko && (
									<>
										<h2 className="h2 mt-4">Versicherungsklassen</h2>
										<div className="grid grid-cols-3 md:grid-cols-3 gap-6 items-center text-center">
											<div>
												<GaugeChart
													id="gauge-chart2"
													nrOfLevels={10}
													percent={(verhicleDetailsData[0].haftpficht - 10) / 0.15 / 100}
													hideText={true}
													needleColor={"#ffffff"}
													needleBaseColor={"#ffffff"}
													animDelay={1000}
													animate={false}
												/>
												Haftpflicht
											</div>
											<div>
												<GaugeChart
													id="gauge-chart2"
													nrOfLevels={10}
													percent={(((verhicleDetailsData[0].teilkasko - 10) / 23) * 100) / 100}
													hideText={true}
													needleColor={"#ffffff"}
													needleBaseColor={"#ffffff"}
													animDelay={1000}
													animate={false}
												/>
												Teilkasko
											</div>
											<div>
												<GaugeChart
													id="gauge-chart2"
													nrOfLevels={10}
													percent={(((verhicleDetailsData[0].vollkasko - 10) / 24) * 100) / 100}
													hideText={true}
													needleColor={"#ffffff"}
													needleBaseColor={"#ffffff"}
													animDelay={1000}
													animate={false}
												/>
												Vollkasko
											</div>
										</div>
										{/* <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 gap-6 items-center mb-12">
											<div className="text-xl">
												<div className="mb-4 text-xl">Haftpflicht :</div>
												<StaticRangeSlider value={[verhicleDetailsData[0].haftpficht]} MIN={9} MAX={25} />
											</div>
											<div className="text-xl">
												<div className="mb-4 text-xl">Teilkasko</div>
												<StaticRangeSlider value={[verhicleDetailsData[0].teilkasko]} MIN={9} MAX={33} />
											</div>
											<div className="text-xl">
												<div className="mb-4 text-xl">Vollkasko:</div>
												<StaticRangeSlider value={[verhicleDetailsData[0].vollkasko]} MIN={9} MAX={34} />
											</div>
										</div> */}
									</>
								)}
						</section>
					</div>

					<script
						type="application/ld+json"
						dangerouslySetInnerHTML={{
							__html: JSON.stringify({
								"@context": "http://schema.org",
								"@type": "Dataset",
								Baujahr: verhicleDetailsData[0].yearFrom + " - " + (verhicleDetailsData[0].yearTo || "heute"),
								HSN: verhicleDetailsData[0].hsn,
								TSN: verhicleDetailsData[0].tsn,
								name: "HSN TSN - " + verhicleDetailsData[0].vehicletype,
								Leistung: verhicleDetailsData[0].power + " PS",
								Hubraum: formatNumber(verhicleDetailsData[0].ccm) + " ccm",
								Kraftstoff: verhicleDetailsData[0].fuel,
								description:
									"Der " +
									verhicleDetailsData[0].vehicletype +
									" ist ein beeindruckendes Auto, der " +
									verhicleDetailsData[0].yearFrom +
									" - " +
									verhicleDetailsData[0].yearTo +
									" hergestellt wurde. Mit " +
									verhicleDetailsData[0].power +
									" PS unter der Haube bietet es eine beeindruckende Leistung und eine dynamische Fahrdynamik. Der " +
									formatNumber(verhicleDetailsData[0].ccm) +
									"-ccm-Motor sorgt für eine kraftvolle Beschleunigung und eine angenehme Fahrerfahrung. Dieses Fahrzeug läuft mit " +
									verhicleDetailsData[0].fuel +
									", was sowohl eine gute Kraftstoffeffizienz als auch eine reibungslose Leistung ermöglicht. Die geschätzte Höhe der KFZ Steuer beträg " +
									(berechneKFZSteuer(verhicleDetailsData[0].ccm, verhicleDetailsData[0].fuel) / 100)
										.toFixed(2)
										.replace(".", ",") +
									" EUR. Die HSN ist " +
									verhicleDetailsData[0].hsn +
									" und die TSN " +
									verhicleDetailsData[0].tsn +
									".",
							}),
						}}
						key="product-jsonld"
					/>
				</>
			)}
			{isLoadingHTSN && (
				<div className="relative z-10 bg-black ">
					<section className="max-w-4xl mx-auto text-center">
						<Spinner />
					</section>
				</div>
			)}
			{!isLoadingHTSN && verhicleDetailsData && verhicleDetailsData.length === 0 && (
				<div className="relative z-10 bg-black ">
					<section className="max-w-4xl mx-auto text-center">
						<Alert>Ups, hier lief etwas schief</Alert>
						<Link to="/" className="btn inline-block mt-8">
							zurück zur Startseite
						</Link>
					</section>
				</div>
			)}

			{showCopiedMsg && (
				<div
					className="animate-showDialog fixed z-30 left-1/2 bottom-2 -translate-x-1/2 flex items-center w-full max-w-xs p-4 text-black bg-white rounded-lg shadow "
					role="alert"
				>
					<div className=" animate-showDialogInner inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg ">
						<svg
							className="fill-black w-4 h-4"
							focusable="false"
							aria-hidden="true"
							viewBox="0 0 24 24"
							data-testid="ContentCopyOutlinedIcon"
						>
							<path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
						</svg>
						<span className="sr-only">Fire icon</span>
					</div>
					<div className="ml-3 text-sm font-normal">In die Zwischenablage kopiert.</div>
				</div>
			)}
		</>
	)
}

export default Details
