import { Link } from "react-router-dom"
import Hero from "../components/Hero"
import { MARKEN } from "../config"
import { convertToSlug } from "../env"
import SearchBar from "../components/SearchBar"
import { useEffect } from "react"

const Home = () => {
	useEffect(() => {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "pageView", clickName: "homepage" })
		} catch (e) {}

		// eslint-disable-next-line
	}, [])

	function scrollToMarken() {
		const doc: any = document
		const { top } = doc.querySelector("#marken").getBoundingClientRect()

		window.scrollTo({
			top: top + window.scrollY,
			left: 0,
			behavior: "smooth",
		})
	}
	function scrollToSearch() {
		const doc: any = document
		const { top } = doc.querySelector("#suche").getBoundingClientRect()

		window.scrollTo({
			top: top + window.scrollY,
			left: 0,
			behavior: "smooth",
		})
	}

	return (
		<>
			<Hero imgAlt={"Auto Wert"} imgPath={"/images/car.png"}>
				<h1 className="h1">
					HSN TSN <small>- Suche</small>
				</h1>
				<p className="mb-8">
					HSN und TSN stehen für Herstellerschlüsselnummer und Typschlüsselnummer. Die Kombination der beiden Nummern
					geben Auskunft über dein Auto (Fahrzeugtyp, Motorleistung, Hubraum usw.).
				</p>
				<p className="mb-8">Du findest deine Schlüsselnummer nicht? Wir helfen dir gerne bei der Suche.🤩</p>
				<div className="-mx-4 ">
					<button className="btn-small-invert mx-4  my-1" onClick={scrollToSearch}>
						Schlüsselnummer finden
					</button>
					<button className="btn-small mx-4 my-1" onClick={scrollToMarken}>
						Marken
					</button>
				</div>
			</Hero>

			<div className="bg-green u-seperator"></div>

			<div className="relative z-10 bg-black">
				<section className="text-center" id="suche">
					<h2 className="h2">Schlüsselnummer suchen 🧐</h2>
					<SearchBar />
				</section>
				<div className="bg-green u-seperator"></div>

				<section className="text-center" id="marken">
					<h2 className="h2">Marken auswählen</h2>

					<ul className=" grid gap-8 grid-cols-2  sm:grid-cols-3  md:grid-cols-4 items-center ">
						{MARKEN.map((marke, index) => (
							<li key={"marke" + index} className="my-4">
								<Link to={"/" + convertToSlug(marke)}>
									<h3 className="h3">{marke}</h3>
									<div className="min-h-[170px] grid grid-cols-1 items-center">
										<img
											src={"/images/" + convertToSlug(marke) + ".png"}
											alt={marke}
											loading="lazy"
											className="w-auto h-auto max-h-[170px] max-w-[130px] mx-auto pb-4"
											onError={({ currentTarget }) => {
												currentTarget.onerror = null
												currentTarget.src = "/images/car.png"
											}}
										/>
									</div>
									<button className="btn-small">
										{marke}
										<br /> Modelle anzeigen
									</button>
								</Link>
							</li>
						))}
					</ul>
				</section>
			</div>

			<div className="bg-green u-seperator"></div>

			<section className="max-w-3xl">
				<h2 className="h2">Wo kann ich die Schlüs­sel­num­mer mei­nes Fahr­zeugs finden?</h2>

				<p className="mb-8">
					Eine Schlüsselnummer ist eine eindeutige Identifikationsnummer, die bei Fahrzeugen in Deutschland verwendet
					wird. Sie besteht aus zwei Teilen: der Herstellerschlüsselnummer (HSN) und der Typschlüsselnummer (TSN). Die
					Kombination aus HSN und TSN bildet die sogenannte Schlüsselnummer.
				</p>
				<p className="mb-8">
					Die Einstufung benötigt die Versicherung um die Typklasse deines Fahrzeugs zu ermitteln. Diese legt nämlich
					fest, wie "günstig" oder "teuer" deine Versicherung wird.
					<i className="text-green">Tipp: Die Typklassen findest du auch auf unterer Seite!</i>
				</p>
				<p className="mb-8">
					Die Schlüsselnummer baut sich aus zwei Bestandteilen auf. Dies ist erstens die HSN und zweitens die TSN.
				</p>
				<h3 className="h3">Die Herstellerschlüsselnummer bzw. HSN</h3>
				<p className="mb-8">
					Die HSN ist eine dreistellige Nummer, die von der Deutschen Automobil Treuhand (DAT) zugewiesen wird. Jeder
					Automobilhersteller erhält eine eigene HSN, die eindeutig für ihn ist. Die HSN wird in der Regel auf jedem
					Fahrzeug des Herstellers eingetragen und dient als Identifikationsnummer für die Hersteller.
				</p>
				<h3 className="h3">Die Typschlüsselnummer, bzw. TSN</h3>
				<p className="mb-8">
					Der zweite Teil zur Fahrzeugidentifikation besteht aus der TSN. Diese Kennung besteht aus 3 Ziffern. Je nach
					Produktionsdatum aus Ziffern oder auch aus Buchstaben. Dieser Code bestimmt den Fahrzeugtyp.
				</p>
				<h3 className="h3">Wo finde ich HSN und TSN?</h3>
				<p className="mb-8">
					Wenn dein Fahrzeug nach Oktober 2005 zugelassen wurde, findest du die Kennung in deinem Fahrzeugschein
					(Zulassungsbescheinigung Teil I) unter Punkt 2.1., bzw. 2.2.. Bei älteren Fahrzeugscheinen sind die Angaben in
					Feld 2 und Feld 3 zu finden.
				</p>

				<h3 className="h3">Wofür wird die Schlüsselnummer verwendet?</h3>
				<p className="mb-8">
					Die Schlüsselnummer dient als eindeutige Identifikation für das jeweilige Fahrzeug und wird unter anderem für
					die Zulassung und die Erstellung von Fahrzeugpapieren verwendet. Darüber hinaus kann die Schlüsselnummer auch
					bei Reparaturen und Wartungen hilfreich sein, da sie Informationen über das Modell und den Hersteller
					bereitstellt.
				</p>
				<h3 className="h3">Wie finde ich die Schlüsselnummer meines Fahrzeugs?</h3>
				<p className="mb-8">
					Die Schlüsselnummer kann in der Regel auf einer Plakette im Motorraum oder im Fahrzeugschein eingetragen sein.
					Alternativ kann sie auch bei einem Kfz-Meister oder einer Autowerkstatt erfragt werden.
				</p>
			</section>
		</>
	)
}
export default Home
