import axios from "axios"
import { useEffect, useState } from "react"
import GaugeChart from "react-gauge-chart"
import { Link, useParams } from "react-router-dom"
import Hero from "../components/Hero"
import Popup from "../components/Popup"
import SearchBar from "../components/SearchBar"
import HelmetSearch from "../components/helmet/HelmetSearch"
import { MARKEN } from "../config"
import Alert from "../elements/Alert"
import Spinner from "../elements/Spinner"
import { capitalizeFirstLetter, convertToAntiSlug, convertToSlug, normalizeModelName, scrollToTop } from "../env"

const Value = () => {
	const { query, year } = useParams<any>()

	const [hsnTsn, setHsnTsn] = useState<any>(null)
	const [furtherModels, setFurtherModels] = useState<any>(null)
	const [isLoadingHTSN, setIsLoadingHTSN] = useState<boolean>(false)
	const [foundMarke, setFoundMarke] = useState<string | null>(null)

	const [showDetails, setShowDetails] = useState<any | null>(null)
	const [moreContent, setMoreContent] = useState<any | null>(null)

	useEffect(() => {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "pageView", clickName: "search" })
		} catch (e) {}

		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		scrollToTop()
		loadHsnTsnData()

		checkIfMarkeIsKnown()
		// loadFurtherModels()
		// eslint-disable-next-line
	}, [])

	function checkIfMarkeIsKnown() {
		const arrayOfStrings: string[] = MARKEN
		const inputString = normalizeModelName(query!)

		const inputArray = inputString.split(" ")

		let matchingIndex = -1

		inputArray.some((str, idx) => {
			const arrIndex = arrayOfStrings.findIndex(arrStr => arrStr.includes(capitalizeFirstLetter(str)))
			if (arrIndex !== -1) {
				matchingIndex = arrIndex
				return true
			}
			return false
		})

		console.log(matchingIndex)
		if (matchingIndex >= 0) {
			loadFurtherModels(MARKEN[matchingIndex])
		}

		// load addional seo copy from html
		if (inputString.trim().toLowerCase() === MARKEN[matchingIndex].toLowerCase()) {
			loadMoreCopy()
		}
	}

	function loadHsnTsnData() {
		setIsLoadingHTSN(true)

		const data = {
			method: "getHSNTSN",
			query: convertToAntiSlug(query).toLowerCase().replace("volkswagen", "VW"),
			year: year,
		}

		const domain = process.env.NODE_ENV === "development" ? "https://hsntsnsuchmaschine.de" : "" //TODO: revert
		// const domain = process.env.NODE_ENV === "development" ? "http://hsntsn.webstanding.de" : ""

		axios
			.post(domain + "/api/data.php?cv=" + Date.now(), data)
			.then(res => {
				if (res.status === 200) {
					setHsnTsn(res.data)
				}
				setIsLoadingHTSN(false)
			})
			.catch(e => {
				setIsLoadingHTSN(false)
			})
	}
	function loadFurtherModels(marke) {
		setFoundMarke(marke)

		const data = {
			marke: marke,
			method: "getModels",
			"api-key": "8f65069d-d9ed-42d3-bae9-7087f3b72def",
		}

		const domain = process.env.NODE_ENV === "development" ? "https://hsntsnsuchmaschine.de" : "" //TODO: revert
		// const domain = process.env.NODE_ENV === "development" ? "http://hsntsn.webstanding.de" : ""

		axios.post(domain + "/api/data.php?cv=" + Date.now(), data).then(res => {
			if (res.status === 200) {
				setFurtherModels(res.data)
			}
		})
	}
	function loadMoreCopy() {
		const inputString = normalizeModelName(query!)

		const domain = process.env.NODE_ENV === "development" ? "https://hsntsnsuchmaschine.de" : "" //TODO: revert
		// const domain = process.env.NODE_ENV === "development" ? "http://hsntsn.webstanding.de" : ""

		axios.get("/contents/" + inputString + ".html?cv=" + Date.now()).then(res => {
			setMoreContent(res.data)
		})
	}

	function berechneKFZSteuer(hubraum, kraftstoff) {
		// Kraftstofftypen und ihre Steuersätze in Cent pro cm³ Hubraum
		const steuersaetze = {
			Benzin: 2.0,
			"Benzin/Alkohol": 2.0,
			"Benzin/Autogas": 2.0,
			"Benzin/Elektro": 2.0,
			"Benzin/Erdgas": 1.3,
			Diesel: 9.5,
			"Diesel/Elektro": 9.5,
			Elektro: 0.0,
			Wankel: 2.0,
			"Wasserstoff/Elektro": 0.0,
			Zweitakt: 2.0,
		}

		// Bestimme den Steuersatz für den angegebenen Kraftstofftyp
		const steuersatz = steuersaetze[kraftstoff]

		// Berechne die Steuer basierend auf dem Hubraum und dem Steuersatz
		const steuer = hubraum * steuersatz

		// Runde die Steuer auf zwei Nachkommastellen und gib sie zurück
		return Math.round(steuer * 100) / 100
	}

	return (
		<>
			<HelmetSearch query={query} year={year} />
			<Hero imgAlt={query} imgPath={"/images/" + convertToSlug(query) + ".png"}>
				<h1 className="h1 pb-0 ">
					HSN TSN - <span className="capitalize">{convertToAntiSlug(query)}</span> {year ? "Baujahr " + year : ""}{" "}
					<span className="whitespace-nowrap">🚙💨</span>
				</h1>
			</Hero>

			<div className="bg-green u-seperator"></div>

			<div className="relative z-10 bg-black">
				<section className="text-center">
					<SearchBar queryParam={convertToAntiSlug(query)} yearParam={convertToAntiSlug(year)} />
					<h2 className="h2 mt-12">
						Suchergebnis: {hsnTsn && hsnTsn.length ? <>🤙</> : <></>}
						<div className="text-white font-Raleway text-base">
							für "<span className="capitalize">{convertToAntiSlug(query)}</span>
							{year ? " Baujahr " + year : ""}"
						</div>
					</h2>

					{isLoadingHTSN ? (
						<Spinner />
					) : (
						<>
							{hsnTsn && hsnTsn.length > 0 ? (
								<div className="relative -ml-8 -mr-8 ">
									<div className=" bg-gradient-to-r from-black/0 via-black/80 to-black h-full absolute right-0 top-0 w-10 "></div>
									<div className=" bg-gradient-to-r from-black via-black/80 to-black/0 h-full absolute left-0 top-0 w-10 "></div>
									<div className="overflow-x-auto ">
										<table>
											<tbody>
												<tr>
													<td className="w-8 bg-black"></td>
													<td>Fahrzeug</td>
													<td>Baujahr </td>
													<td>Leistung</td>
													<td>ccm</td>
													<td>Treibstoff</td>
													<td></td>
													{/* <td>HSN/TSN</td>
													<td>Haftpflicht</td>
													<td>Teilkasko</td>
													<td>Vollkasko</td> */}
													<td className="w-8 bg-black"></td>
												</tr>
												{hsnTsn.length &&
													hsnTsn.map((car, index) => (
														<tr key={"hsntsn" + index}>
															{" "}
															<td className="w-8 bg-black"></td>
															<td>{car.vehicletype}</td>
															<td className="whitespace-nowrap">
																{car.yearFrom} - {car.yearTo}
															</td>
															<td>{car.power} PS</td>
															<td>{car.ccm} ccm</td>
															<td>{car.fuel}</td>
															<td>
																<Link
																	to={"/d/" + convertToSlug(car.vehicletype) + "/" + car.id}
																	className="btn-small cursor-pointer "
																>
																	anzeigen
																</Link>
															</td>
															<td className="w-8 bg-black"></td>
														</tr>
													))}
											</tbody>
										</table>
									</div>
								</div>
							) : (
								<Alert>Es sind leider keine Daten für dieses Auto verfügbar.</Alert>
							)}
						</>
					)}
					{hsnTsn && hsnTsn.length > 80 && (
						<div className="text-left mt-12">
							<h2 className="h2">Dein gesuchtes Auto ist nicht dabei?</h2>
							<p>Bitte verfeinere deine Suche. Es werden nur die ersten 100 Treffer angezeigt.</p>
						</div>
					)}
					{furtherModels && furtherModels.length > 0 && (
						<div className="text-left mt-12">
							<h2 className="h2">Weitere Modelle von {foundMarke}</h2>
							<p>
								Weitere beliebte Modell von {foundMarke} sind:
								{furtherModels.map((model, index) => {
									if (model.name.includes("(all")) {
									} else {
										return (
											<>
												{Object.keys(furtherModels).length - 1 === index && index > 0 ? " und " : ""}
												<strong>
													<a className="underline" href={"/" + foundMarke + "-" + model.name}>
														{model.name}
													</a>
												</strong>
												{Object.keys(furtherModels).length - 2 <= index ? "" : ", "}
											</>
										)
									}
								})}
							</p>
						</div>
					)}

					{moreContent && <div className="pt-12 text-left" dangerouslySetInnerHTML={{ __html: moreContent }} />}
				</section>
			</div>

			{showDetails && (
				<Popup close={() => setShowDetails(null)}>
					<section className="flex h-full">
						<div className="m-auto">
							<h2 className="h2">{showDetails.vehicletype}</h2>

							<div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-4 gap-2  pb-6 text-left">
								<div className="whitespace-nowrap">
									<strong>Baujahr:</strong> {showDetails.yearFrom} - {showDetails.yearTo}
								</div>
								<div>
									<strong>Leistung: </strong>
									{showDetails.power} PS
								</div>
								<div>
									<strong>Hubraum: </strong>
									{showDetails.ccm} ccm
								</div>
								<div>
									<strong>Kraftstoff: </strong>
									{showDetails.fuel}
								</div>
								<div>
									<strong>KFZ Steuer: </strong>ca.{" "}
									{(berechneKFZSteuer(showDetails.ccm, showDetails.fuel) / 100).toFixed(2).replace(".", ",")} EUR
								</div>
								<div>
									<strong className="text-green">HSN</strong>: {showDetails.hsn}
								</div>
								<div>
									<strong className="text-green">TSN</strong>: {showDetails.tsn}
								</div>
							</div>

							<div className="grid grid-cols-3 md:grid-cols-3 gap-6 items-center ">
								<div>
									<GaugeChart
										id="gauge-chart2"
										nrOfLevels={10}
										percent={(showDetails.haftpficht - 10) / 0.15 / 100}
										hideText={true}
										needleColor={"#ffffff"}
										needleBaseColor={"#ffffff"}
										animDelay={1000}
										animate={false}
									/>
									Haftpflicht
								</div>
								<div>
									<GaugeChart
										id="gauge-chart2"
										nrOfLevels={10}
										percent={(((showDetails.teilkasko - 10) / 23) * 100) / 100}
										hideText={true}
										needleColor={"#ffffff"}
										needleBaseColor={"#ffffff"}
										animDelay={1000}
										animate={false}
									/>
									Teilkasko
								</div>
								<div>
									<GaugeChart
										id="gauge-chart2"
										nrOfLevels={10}
										percent={(((showDetails.vollkasko - 10) / 24) * 100) / 100}
										hideText={true}
										needleColor={"#ffffff"}
										needleBaseColor={"#ffffff"}
										animDelay={1000}
										animate={false}
									/>
									Vollkasko
								</div>
							</div>
						</div>
					</section>
				</Popup>
			)}
		</>
	)
}
export default Value
