import { Helmet } from "react-helmet"
import { convertToAntiSlug } from "../../env"

const Search = ({ query, year }) => {
	function capitalizeAllWords(str: string = "") {
		const split = (" " + str).split(" ")

		for (let index = 0; index < split.length; index++) {
			split[index] = capitalizeFirstLetter(split[index])
		}
		return split.join(" ")
	}

	function capitalizeFirstLetter(str: string = "") {
		return str.charAt(0).toUpperCase() + str.slice(1)
	}

	return (
		<Helmet>
			<title>
				{capitalizeAllWords(convertToAntiSlug(query))} {year ? year : ""} - HSN TSN Suche
			</title>
			<meta
				name="description"
				content={"Diese HSN TSN haben wir zu " + convertToAntiSlug(query) + (year ? " " + year : "") + "gefunden."}
			/>
			<link rel="canonical" href={"https://hsntsnsuchmaschine.de/" + query} />

			<script type="application/ld+json">
				{JSON.stringify({
					"@context": "https://schema.org",
					"@type": "BreadcrumbList",
					itemListElement: [
						{
							"@type": "ListItem",
							position: 1,
							name: "Schlüsselnummer",
							item: "https://hsntsnsuchmaschine.de/",
						},
						{
							"@type": "ListItem",
							position: 2,
							name: convertToAntiSlug(query),
							item: "https://hsntsnsuchmaschine.de/" + query + (year ? "/" + year : ""),
						},
					],
				})}
			</script>
		</Helmet>
	)
}

export default Search
