import React from "react"
import { formatNumber } from "../helper"

export default function KBATable({ data }) {
	// function getTableByYear(data: any) {
	// 	// Group data by year and calculate average amounts
	// 	const amountByYear: { [year: number]: number[] } = {}
	// 	for (const item of data) {
	// 		const timestamp = parseInt(item.time)
	// 		const year = new Date(timestamp * 1000).getFullYear()
	// 		const amount = parseInt(item.amount)
	// 		if (!amountByYear[year]) {
	// 			amountByYear[year] = [amount]
	// 		} else {
	// 			amountByYear[year].push(amount)
	// 		}
	// 	}

	// 	let collection = []
	// 	for (const year in amountByYear) {
	// 		if (amountByYear.hasOwnProperty(year)) {
	// 			const amounts = amountByYear[year]
	// 			const avgAmount = Math.round(amounts.reduce((a, b) => a + b) / amounts.length)

	// 			collection[`${year}`] = avgAmount
	// 		}
	// 	}

	// 	let table: any = []
	// 	for (var i = 0; i < Object.keys(collection).length; i++) {
	// 		const year = Object.keys(collection)[i]
	// 		const amount = collection[year]
	// 		let change: any = 0

	// 		if (i > 0) {
	// 			const prevYear = Object.keys(collection)[i - 1]
	// 			const prevYearAmount = collection[prevYear]
	// 			if (prevYearAmount) {
	// 				change = ((amount - prevYearAmount) / prevYearAmount) * 100 || 0
	// 			}
	// 		}

	// 		if (amount > 0) {
	// 			table.push(
	// 				<tr key={"kbatable" + i}>
	// 					<td className="w-8 bg-black"></td>
	// 					<td>{year}</td>
	// 					<td>{formatNumber(amount)}</td>
	// 					<td>
	// 						<>
	// 							{!!change ? (
	// 								<>
	// 									{change > 0.001 && "+"}
	// 									{change.toFixed(2).replace(".", ",")} %
	// 								</>
	// 							) : (
	// 								"0.00 %"
	// 							)}
	// 						</>
	// 					</td>
	// 					<td className="w-8 bg-black"></td>
	// 				</tr>
	// 			)
	// 		}
	// 	}
	// 	return table
	// }

	return (
		<div className="pb-12">
			<h2 className="h2 mt-4">Anzahl angemeldeter "{data.vehicletype}" laut KBA</h2>

			<div className="overflow-x-auto ">
				<table>
					<tbody>
						<tr>
							<td className="w-8 bg-black"></td>
							<td>Jahr</td>
							<td>Anazahl Fahrzeuge </td>
							<td className="w-8 bg-black"></td>
						</tr>

						<tr>
							<td className="w-8 bg-black"></td>
							<td>2019</td>
							<td>{formatNumber(data.anzahl2019)}</td>
							<td className="w-8 bg-black"></td>
						</tr>
						<tr>
							<td className="w-8 bg-black"></td>
							<td>2020</td>
							<td>{formatNumber(data.anzahl2020)}</td>
							<td className="w-8 bg-black"></td>
						</tr>
						<tr>
							<td className="w-8 bg-black"></td>
							<td>2021</td>
							<td>{formatNumber(data.anzahl2021)}</td>
							<td className="w-8 bg-black"></td>
						</tr>
						<tr>
							<td className="w-8 bg-black"></td>
							<td>2022</td>
							<td>{formatNumber(data.anzahl2022)}</td>
							<td className="w-8 bg-black"></td>
						</tr>
						<tr>
							<td className="w-8 bg-black"></td>
							<td>2023</td>
							<td>{formatNumber(data.anzahl2023)}</td>
							<td className="w-8 bg-black"></td>
						</tr>
						<tr>
							<td className="w-8 bg-black"></td>
							<td>2024</td>
							<td>{formatNumber(data.anzahl2024)}</td>
							<td className="w-8 bg-black"></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	)
}
